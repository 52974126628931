import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    listMedia(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/crm/list_media', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getMedia(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/crm/get_media', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addMedia(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/crm/add_media', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editMedia(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/crm/edit_media', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteMedia(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/crm/delete_media', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDefaultMedia(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/crm/get_default_media', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImageMedia(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/crm/upload_image_media/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadVideoMedia(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/crm/upload_video_media/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addImageMedia(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/crm/add_image_media', params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeImageMedia(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/crm/remove_image_media', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
